@use 'colors';
@import 'mixins';
@import 'responsive/utils/mediaQueries.module';

// Mobile only styles
.button {
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	background-color: transparent;
	padding: 0;
	width: 36px;
	height: 48px;
	color: inherit;
	padding-inline-start: 2px;

	@include onLargeOrGreater {
		display: none;
	}
}

.menuIcon {
	width: 24px;
}
