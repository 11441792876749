@import 'responsive/utils/mediaQueries.module';

.footer {
	display: flex;
	flex-direction: column;
	margin-block: 16px;
	padding-inline: 32px;

	@include onSmall {
		padding-inline: 16px;
	}

	@include onLessThanLarge {
		row-gap: 56px;
	}

	@include onLargeOrGreater {
		row-gap: 88px;
	}
}

.middleModule {
	display: flex;
	flex-direction: column;

	@include onSmall {
		row-gap: 24px;
	}

	@include onMediumOrGreater {
		row-gap: 8px;
	}
}
