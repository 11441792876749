@import 'mixins';
@import 'responsive/utils/mediaQueries.module';

.social {
	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 20px;
	margin: 0;
	padding: 0;
	list-style: none;
	flex-wrap: wrap;

	@include onSmall {
		justify-content: flex-start;
	}
}

.socialLink {
	padding: 0;
	padding-block: 7px;

	@include onLessThanLarge {
		padding-block: 13px;
	}

	@include onLargeOrGreater {
		width: fit-content;
	}
}
