@use 'outline';
@import 'mixins';

.userIconContainer {
	height: 100%;
	position: relative;

	&:has(input:focus-visible) {
		outline: none;
	}

	:global(.k) &:has(input:focus-visible) {
		@extend %default-outline;
	}
}

.userIcon {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: none;
	background-color: inherit;
	padding: 0;
	width: 100%;
	height: 100%;
}

.iconWrapper {
	display: flex;
	justify-content: center;
	align-items: baseline;
}

.icon {
	width: 100%;
	height: px2rem(22);
}
