@use 'colors';
@import 'mixins';
@import 'responsive/utils/mediaQueries.module';

.header {
	position: sticky;
	top: 0;
	z-index: 1; // added to be above chatbot and main in the stacking context
	isolation: isolate;
	background-color: colors.$background-default;
	width: 100%;
}
