@import 'responsive/utils/mediaQueries.module';

.userMenu {
	display: flex;
	flex: 1 1;
	align-items: center;
	justify-content: flex-end;
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
	list-style: none;

	@include onLargeOrGreater {
		gap: 20px;
	}
}
