@use '../LineAnimatedBase.module.scss';

// Variants
.default,
.inverse {
	cursor: pointer;
	border: 0;
	background: transparent;
	height: auto;
	text-align: start;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
}
