@use 'colors';
@use 'texts';
@import 'mixins';
@import 'responsive/utils/mediaQueries.module';

.footer {
	margin: 0 auto;
	background-color: colors.$background-default;
}

.legal {
	@include onMedium {
		text-align: center;

		div {
			text-align: start;
		}
	}
}

.form,
.legal {
	@include onMediumOrGreater {
		margin-right: auto;
		margin-left: auto;
		width: px2rem(382);
	}

	@include onLargeOrGreater {
		width: px2rem(428);
	}
}

.title {
	text-align: start;

	@extend %text-uppercase-m;

	@include onMediumOrGreater {
		text-align: center;
	}
}
