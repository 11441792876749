@use 'texts';
@use 'colors';
@use 'breakpoints';
@use 'outline';
@use 'motion';
@import 'mixins';
@import '@fukku/button/styles/states';
@import 'responsive/utils/mediaQueries.module';

@mixin decoration {
	position: relative;
	padding: 4px 0;

	&::after {
		position: absolute;
		inset-inline-start: 0;
		inset-block-end: 0;
		width: 100%;
		height: 1px;
		color: inherit;
		border-block-end: 1px solid;
		border-color: inherit;
		transition: border-color motion.$duration-short3 motion.$easing-calm;
		content: '';

		@media (prefers-reduced-motion: reduce) {
			transition: none;
		}
	}
}

.label {
	@extend %text-uppercase-m;

	display: block;
	padding-top: 2px;

	@include onLessThanLarge {
		display: none;
		width: 100%;
		color: inherit;
		text-align: center;
		white-space: nowrap;
	}
}

.text span {
	@include decoration;

	&::after {
		border-color: transparent;
		transition-timing-function: motion.$easing-accent;

		@media (prefers-reduced-motion: reduce) {
			transition-timing-function: none;
		}
	}
}

.totalItemsText {
	display: flex;
	align-items: center;

	span {
		padding-inline-end: 4px;
	}
}

.totalItemsNumber {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	min-width: 25px;
}

.totalItemsContainer {
	all: unset;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	height: 100%;
	gap: 0;
	transition: color motion.$duration-short3 motion.$easing-calm;

	&.active {
		.text span {
			@include decoration;
		}

		.totalItemsText span {
			padding-inline-end: 4px;
		}

		.totalItemsText span:empty {
			padding-inline-end: 0;
		}
	}

	&:active:not([aria-disabled='true']),
	&:hover:not([aria-disabled='true']) {
		.text span {
			@include decoration;
		}

		.totalItemsText span {
			padding-inline-end: 4px;
		}

		.totalItemsText span:empty {
			padding-inline-end: 0;
		}
	}
}

.default {
	@include states(
		'color',
		colors.$content-accent,
		colors.$content-accent-hover,
		colors.$content-accent-active
	);
}

.inverse {
	@include states(
		'color',
		colors.$content-accent-on-inverse,
		colors.$content-accent-on-inverse-hover,
		colors.$content-accent-on-inverse-active
	);
}
