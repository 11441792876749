@use 'colors';
@import 'mixins';
@import 'responsive/utils/mediaQueries.module';

.form {
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 32px;
	margin-bottom: 24px;

	@include onLargeOrGreater {
		margin-bottom: 16px;
	}
}

.inputContainer {
	display: flex;
	flex-direction: column;
	gap: 8px;

	@include onLargeOrGreater {
		flex-direction: row;
	}
}

.genericError {
	color: colors.$semantic-error;
}

.submitButton {
	min-width: fit-content;

	span {
		overflow: unset;
		white-space: nowrap;
	}
}
