@import 'mixins';

.userLinkContainer {
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
}

.accessibilityButton {
	position: absolute;
	right: -20px;
	height: px2rem(20);
	width: px2rem(20);
	border: 0;
	padding: 0;
	opacity: 0;
	background-color: transparent;

	&:focus-visible {
		opacity: 1;
	}
}
