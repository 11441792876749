@use 'colors';
@use 'motion';
@import '@fukku/button/styles/states';
@import 'mixins';

[aria-disabled='true'] {
	&.default,
	&.inverse {
		color: colors.$content-disabled;
	}
}

.default {
	@include states(
		'color',
		colors.$content-accent,
		colors.$content-accent-hover,
		colors.$content-accent-active
	);
}

.inverse {
	@include states(
		'color',
		colors.$content-accent-on-inverse,
		colors.$content-accent-on-inverse-hover,
		colors.$content-accent-on-inverse-active
	);
}

.default,
.inverse {
	transition: color motion.$duration-short3 motion.$easing-calm;

	&.unselected {
		color: colors.$content-accent-unselected;
	}

	&:active:not(.topBar),
	&.active:not(.topBar) {
		.text {
			position: relative;
			padding: 4px 0;

			&::after {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: px2rem(1);
				color: inherit;
				border-bottom: 1px solid;
				border-color: inherit;
				transition: border-color motion.$duration-short3 motion.$easing-calm;
				content: '';
			}
		}
	}
}
