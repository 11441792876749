@use 'colors';
@use 'motion';
@import 'responsive/utils/mediaQueries.module';

@keyframes apply-shadow {
	0% {
		box-shadow: 0 100vh 0 100vh transparent;
	}

	100% {
		box-shadow: 0 100vh 0 100vh colors.$background-overlay;
	}
}

.button {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	border: none;
	background-color: inherit;
	padding: 0;
	width: 100%;
	height: 100%;
}

.searchBackdrop {
	position: fixed;
	top: var(--header-height);
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: transparent;
}

.searchBackdrop.open {
	visibility: visible;

	@include onLessThanLarge {
		animation:
			overlay-black motion.$duration-short3 motion.$easing-calm forwards,
			fixed-background 0ms motion.$easing-calm motion.$duration-short3 forwards;
	}

	@include onLargeOrGreater {
		animation: overlay-black motion.$duration-medium2 motion.$easing-calm forwards;
	}
}

.searchBackdrop.close {
	@include onLessThanLarge {
		animation: overlay-transparent motion.$duration-short3 motion.$easing-calm forwards;
	}

	@include onLargeOrGreater {
		background-color: transparent;
		visibility: hidden;
		animation: overlay-transparent motion.$duration-short1 motion.$easing-accent forwards;
	}
}

@keyframes overlay-black {
	from {
		background-color: transparent;
		visibility: hidden;
	}

	to {
		background-color: colors.$background-overlay;
		visibility: visible;
	}
}

@keyframes overlay-transparent {
	from {
		background-color: colors.$background-overlay;
		visibility: visible;
	}

	to {
		background-color: transparent;
		visibility: hidden;
	}
}

@keyframes fixed-background {
	to {
		background-color: colors.$background-default;
	}
}
