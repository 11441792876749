@use 'texts';
@use 'colors';
@import 'responsive/utils/mediaQueries.module';

.item {
	width: 36px;
	height: 48px;

	@include onLargeOrGreater {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: auto;
		height: 100%;
	}

	&.fill {
		flex: auto;
	}
}

.button {
	align-content: center;
	height: 100%;

	@include onLargeOrGreater {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}

.link {
	@extend %text-uppercase-m;

	align-content: center;
	height: 100%;

	@include onLargeOrGreater {
		text-decoration: none;
	}

	@include onLessThanLarge {
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		background-color: inherit;
		padding: 0;
		width: 100%;
		height: 100%;
	}
}

.icon {
	width: 100%;
	height: px2rem(22);

	@include onLargeOrGreater {
		display: none;
	}
}

// Desktop only style
.text {
	display: none;
	white-space: nowrap;

	@include onLargeOrGreater {
		display: initial;
		text-decoration: none;
	}
}
