@import 'mixins';
@import 'responsive/utils/mediaQueries.module';

.content {
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: flex-start;
	padding: 6px 10px;
	height: px2rem(60);

	@include onLargeOrGreater {
		align-items: center;
		padding: 0 32px;
		height: px2rem(56);
	}
}
