@use 'colors';
@import 'mixins';
@import '@fukku/button/styles/states';

$width-button: 48;

.closeButton {
	float: left;
	border: none;
	background-color: transparent;
	width: px2rem($width-button);
	height: 3rem;
	cursor: pointer;

	@include states(
		'color',
		colors.$content-accent,
		colors.$content-accent-hover,
		colors.$content-accent-active
	);
}

:export {
	/* stylelint-disable property-no-unknown */
	CLOSE_BUTTON_WIDTH: $width-button;
	/* stylelint-enable property-no-unknown */
}
